import { Controller } from '@hotwired/stimulus'
import appsignal from '../../../../javascript/v4/appsignal'

/**
 * ToggleController
 *
 * A Stimulus.js controller to toggle visibility of form elements based on select box values.
 * This is not standard behavior for StimulusJS.
 *
 * Usage:
 *
 * Add the attribute `data-toggle-supported-values` to the elements you want to toggle.
 * The attribute should contain a comma-separated list of the select box values
 * for which the element should be visible.
 *
 * Example:
 *
 * <div data-toggle-target="element" data-toggle-supported-values="Option1,Option2">
 *   <!-- your content -->
 * </div>
 */
export default class extends Controller {
  static values = {
    default: String,
  }
  static targets = ['element']
  static classes = ['hidden']

  connect() {
    this.checkSanity()
    this.updateElementsVisibility()
  }

  /**
   * Checks if each target element has at least one supported value in the data attribute.
   * Logs errors to console and AppSignal if any are found.
   */
  checkSanity() {
    const datasetKey = this.dataAttributeForSupportedValues()

    for (let element of this.elementTargets) {
      try {
        if (!element.dataset[datasetKey]) {
          throw new Error(
            `Element with testId '${element.dataset.testid}' is missing dataset key '${datasetKey}'.`,
          )
        }
      } catch (error) {
        appsignal.sendError(error)
        console.error(error)
      }
    }
  }

  /**
   * Generates the name of the data attribute for supported values, based on the controller's identifier.
   *
   * @returns {string} Data attribute name.
   */
  dataAttributeForSupportedValues() {
    return this.camelize(`${this.identifier}-supported-values`)
  }

  /**
   * Updates the visibility of target elements based on the current value of the select box.
   *
   * @param {Event} [event] - The change event from the select box.
   */
  updateElementsVisibility(event) {
    const selectValue = event ? event.target.value : this.defaultValue

    for (let element of this.elementTargets) {
      const supportedValues = element.dataset[this.dataAttributeForSupportedValues()]

      if (supportedValues) {
        const supportedValuesArray = supportedValues.split(',')
        element.classList.toggle(this.hiddenClass, !supportedValuesArray.includes(selectValue))
      }
    }
  }

  /**
   * Camelize a string
   *
   * Vendored from https://github.com/hotwired/stimulus/blob/v3.2.2/src/core/string_helpers.ts
   *
   * @param {String} value
   * @return {String} camelized string
   */
  camelize(value) {
    return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase())
  }
}
