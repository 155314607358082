import { Controller } from '@hotwired/stimulus'

/**
 * This Stimulus controller facilitates the dynamic re-ordering of form fields
 * based on the selected publishable type. Primary and secondary fields are defined
 * for each publishable type and are stored in data attributes on the controller element.
 *
 * Usage:
 * - Define the primary fields for each publishable type in the primaryFields value.
 * - Specify the order of all fields in the allFields value.
 * - Assign the primaryContainer and secondaryContainer targets to the containers
 * - Assign the field targets to the fields
 */
export default class extends Controller {
  static targets = [
    'primaryContainer',
    'secondaryContainer',

    // Publication Types
    'articleContainer',
    'bookContainer',
    'miscellaneousContainer',
    'patentContainer',
    'periodicalContainer',
    'proceedingsContainer',
    'reportContainer',
    'standardContainer',
    'thesisContainer',
    'unknownContainer',

    // Fields
    'abstractField',
    'addressField',
    'authorsField',
    'doiField',
    'editionField',
    'editorsField',
    'identifiersField',
    'isbnField',
    'issnField',
    'journalField',
    'languageField',
    'numberField',
    'organizationField',
    'pagesField',
    'parentPublicationField',
    'pmidField',
    'publishableTypeField',
    'publishedOnField',
    'publisherField',
    'repositoryIdField',
    'seriesField',
    'standardKindField',
    'standardPredecessorsField',
    'standardStatusField',
    'standardSuccessorsField',
    'subtitleField',
    'titleField',
    'uniformTitleField',
    'urlField',
    'volumeField',
  ]

  static values = {
    allFields: Array,
    primaryFields: Object,
    publishableTypeSpecificFields: Object,
  }

  connect() {
    if (this.hasPublishableTypeFieldTarget) {
      this.updateFields()
    }
  }

  updateFields() {
    const publishableType = this.publishableTypeFieldTarget.querySelector('select').value
    const primaryFieldsForType = this.primaryFieldsValue[publishableType]

    // First, move all publication type specific fields to their respective containers
    Object.entries(this.publishableTypeSpecificFieldsValue).forEach(([key, fields]) => {
      const container = this[`${this.camelize(key)}ContainerTarget`]

      fields.forEach((field) => {
        console.log(`Moving ${field}Target to ${key} container`)
        container.appendChild(this[`${field}Target`])
      })
    })

    // Second, place primary fields in order
    primaryFieldsForType.forEach((field) => {
      console.log(`Moving ${field}Target to primary container`)
      this.primaryContainerTarget.appendChild(this[`${field}Target`])
    })

    // Third, place all remaining fields as secondary fields in the order defined in allFieldsValue
    this.allFieldsValue.forEach((field) => {
      const hasMethod = `${this.camelize(`has-${field}`)}Target`
      if (!primaryFieldsForType.includes(field) && this[hasMethod]) {
        console.log(`Moving ${field}Target to secondary container`)
        this.secondaryContainerTarget.appendChild(this[`${field}Target`])
      } else {
        console.log(`Skipping ${field}Target`)
      }
    })
  }

  /**
   * Camelize a string, cutting the string by multiple separators like
   * hyphens, underscores and spaces.
   *
   * @param {text} string Text to camelize
   * @return string Camelized text
   */
  camelize(text) {
    return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2, offset) {
      if (p2) return p2.toUpperCase()
      return p1.toLowerCase()
    })
  }
}
