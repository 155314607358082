import Reveal from '@stimulus-components/reveal'

export default class extends Reveal {
  static targets = [
    'leftbar',
    'rightbar',
    'rangeLine',
    'rangeLeftButton',
    'rangeRightButton',
    'histogramItem',
    'leftInputBox',
    'rightInputBox',
    'yearElement',
    'yearInput',
    'form',
  ]
  static values = {
    years: Array,
  }
  connect() {
    this.range = {
      minyear: 1000,
      maxyear: 7000,
    }
    this.setRange()
    this.histogram()
  }

  update() {
    this.formTarget.requestSubmit()
  }

  updateValues() {
    this.yearInputTarget.value =
      this.leftInputBoxTarget.value + '-' + this.rightInputBoxTarget.value
    this.update()
  }

  calculateRight(value) {
    return (
      ((parseInt(this.rightbarTarget.max) - value) /
        (parseInt(this.rightbarTarget.max) - parseInt(this.rightbarTarget.min))) *
      100
    )
  }

  calculateLeft(value) {
    return (
      ((value - parseInt(this.leftbarTarget.min)) /
        (parseInt(this.leftbarTarget.max) - parseInt(this.leftbarTarget.min))) *
      100
    )
  }

  minTrigger(event) {
    var right = this.calculateRight(this.range.maxyear)
    var left = this.calculateLeft(this.leftbarTarget.value)
    if (left + right <= 100 && left >= 0 && right >= 0) {
      this.range.minyear = this.leftInputBoxTarget.value = parseInt(this.leftbarTarget.value)
      this.rangeLineTarget.style = 'left:' + left + '%; right: ' + right + '%;'
      this.rangeLeftButtonTarget.style = 'left:' + left + '%;'
      this.updateValues()
    } else {
      this.leftbarTarget.value = this.range.minyear
    }
  }

  rightInputTrigger(event) {
    if (this.rightInputBoxTarget.value / 1000 < 1) return
    this.rightbarTarget.value = this.rightInputBoxTarget.value
    var right =
      ((parseInt(this.rightbarTarget.max) - this.rightInputBoxTarget.value) /
        (parseInt(this.rightbarTarget.max) - parseInt(this.rightbarTarget.min))) *
      100
    var left = this.calculateLeft(this.range.minyear)

    if (left + right <= 100) {
      this.range.maxyear = this.rightInputBoxTarget.value
      this.rangeLineTarget.style = 'right:' + right + '%; left: ' + left + '%;'
      this.rangeRightButtonTarget.style = 'right:' + right + '%;'
      this.updateValues()
    } else {
      this.rightInputBoxTarget.value = this.rightbarTarget.value = this.range.maxyear
    }
  }
  leftInputTrigger(event) {
    if (this.leftInputBoxTarget.value / 1000 < 1) return
    this.leftbarTarget.value = this.leftInputBoxTarget.value
    var right = this.calculateRight(this.range.maxyear)
    var left = this.calculateLeft(this.leftInputBoxTarget.value)
    if (left + right <= 100) {
      this.range.minyear = this.leftInputBoxTarget.value
      this.rangeLineTarget.style = 'right:' + right + '%; left: ' + left + '%;'
      this.rangeLeftButtonTarget.style = 'left:' + left + '%;'
      this.updateValues()
    } else {
      this.leftInputBoxTarget.value = this.leftbarTarget.value = this.range.minyear
    }
  }
  maxTrigger(event) {
    var right = this.calculateRight(this.rightbarTarget.value)
    var left = this.calculateLeft(this.range.minyear)
    if (left + right <= 100 && left >= 0 && right >= 0) {
      this.range.maxyear = this.rightInputBoxTarget.value = this.rightbarTarget.value
      this.rangeLineTarget.style = 'right:' + right + '%; left: ' + left + '%;'
      this.rangeRightButtonTarget.style = 'right:' + right + '%;'
      this.updateValues()
    } else {
      this.rightbarTarget.value = this.range.maxyear
    }
  }

  setRange() {
    this.range.maxyear = this.rightInputBoxTarget.value = this.rightbarTarget.value
    this.range.minyear = this.leftInputBoxTarget.value = parseInt(this.leftbarTarget.value)
    var right = this.calculateRight(this.range.maxyear)
    var left = this.calculateLeft(this.range.minyear)
    this.rangeLineTarget.style = 'right:' + right + '%; left:' + left + '%'
    this.rangeLeftButtonTarget.style = 'left:' + left + '%'
    this.rangeRightButtonTarget.style = 'right:' + right + '%;'
  }

  updateCheckbox(event) {
    this.yearInputTarget.value = event.target.value
    this.update()
  }

  applyYearFilter(event) {
    this.leftInputBoxTarget.value = event.currentTarget.dataset.value
    this.updateValues()
  }

  histogram() {
    var values = []
    var total = this.yearsValue
      .filter((y) => !y.key.includes('null'))
      .reduce((a, b) => a + (b.count || 0), 0)
    this.yearsValue
      .filter((y) => !y.key.includes('null'))
      .reverse()
      .forEach((year, index) => {
        values.push({
          x: (index / (this.yearsValue.length - 1)) * 100 + '%',
          y: 100 - (year.count / total) * 100 + '%',
          width: (1 / (this.yearsValue.length - 1)) * 100 + '%',
          height: (year.count / total) * 100 + '%',
          year: year.key,
          freq: year.count,
          id: index,
        })
      })
    var histogram = {
      histogram: values,
    }
    histogram.histogram.forEach((rectangle) => {
      var svgns = 'http://www.w3.org/2000/svg'
      var rect = document.createElementNS(svgns, 'rect')
      var title = document.createElementNS(svgns, 'title')
      rect.setAttribute('x', rectangle.x)
      rect.setAttribute('y', rectangle.y)
      rect.setAttribute('height', rectangle.height)
      rect.setAttribute('width', rectangle.width)
      rect.setAttribute('data-value', rectangle.year)
      rect.setAttribute(
        'data-action',
        'click->publication-search--filters-component--year#applyYearFilter',
      )
      title.textContent = `${rectangle.year} (${rectangle.freq})`
      rect.classList.add('hover:bg-sky-700')
      rectangle.year >= this.leftInputBoxTarget.value &&
      rectangle.year <= this.rightInputBoxTarget.value
        ? (rect.style = 'fill: rgba(47, 97, 253, var(--tw-bg-opacity)); ')
        : (rect.style = 'fill: rgba(147, 197, 253, var(--tw-bg-opacity));')
      rect.appendChild(title)
      this.histogramItemTarget.appendChild(rect)
    })
  }
}
