import { Controller } from '@hotwired/stimulus'

// Controller for a 'user_role' radio button with no value.
// When selected, this option is disabled to exclude it from form data.
// On deselection, the option is re-enabled.
export default class extends Controller {
  connect() {
    this.toggleDisabled()
  }

  toggleDisabled() {
    this.element.disabled = this.element.checked
  }
}
