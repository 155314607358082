import { Controller } from '@hotwired/stimulus'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

export default class extends Controller {
  static targets = ['fileInput', 'hiddenInput']

  static values = {
    maxFileSize: Number,
  }

  connect() {
    if (this.hasFileInputTarget) {
      this.configs = {
        maxFileSize: this.maxFileSizeValue,
      }
      this.uppy = this.initUppy()
      this.initUppyAws()
      this.initUppyUploadSuccess()
    }
  }

  disconnect() {
    this.uppy.close()
  }

  uploadFile(event) {
    const files = Array.from(event.target.files)

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
        super.add(event)
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  }

  initUppy() {
    let uppy = new Uppy({
      id: this.fileInputTarget.id,
      autoProceed: true,
      restrictions: {
        maxFileSize: this.configs.maxFileSize,
        allowedFileTypes: this.configs.allowedFileTypes,
      },
    })

    return uppy
  }

  initUppyAws() {
    this.uppy.use(AwsS3, {
      getUploadParameters(file) {
        const filename = encodeURIComponent(file.meta.name)
        const type = encodeURIComponent(file.meta.type)
        return fetch(`/account/brand/presign?filename=${filename}&type=${type}`).then((response) =>
          response.json(),
        )
      },
    })
  }

  initUppyUploadSuccess() {
    var self = this
    this.uppy.on('upload-success', function (file, response) {
      self.setHiddenInput(file)
    })
  }

  setHiddenInput(file) {
    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    this.hiddenInputTarget.value = this.uploadedFileData(file)
  }

  uploadedFileData(file) {
    // construct uploaded file data in the format that Shrine expects
    return JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    })
  }
}
