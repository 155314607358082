import Timeago from '@stimulus-components/timeago'
import { enUS, de } from 'date-fns/locale'

export default class extends Timeago {
  connect() {
    super.connect()
  }

  get locale() {
    switch (document.documentElement.lang) {
      case 'de':
        return de
      default:
        return enUS
    }
  }
}
