import Appsignal from '@appsignal/javascript'
import { plugin as breadcrumbsConsole } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as breadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network'

function metaContent(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element && element.content
}

const appsignal = new Appsignal({
  key: metaContent('appsignal-key'),
  namespace: 'frontend-v4',
  revision: metaContent('librario-version'),
  environment: metaContent('librario-environment'),
})

appsignal.use(breadcrumbsConsole())
appsignal.use(breadcrumbsNetwork())

export default appsignal
