import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.open()
    this.element.addEventListener('close', this.handleClose.bind(this))
  }

  disconnect() {
    this.close()
    this.element.removeEventListener('close', this.handleClose.bind(this))
  }

  open() {
    this.element.showModal()
    this.element.setAttribute('aria-hidden', 'false')
  }

  close() {
    this.element.close()
  }

  handleClose() {
    this.removeSrcFromNextTurboFrame()
    this.element.setAttribute('aria-hidden', 'true')
    // Self-destruct after closing, to better support jumping in history back and forth
    this.element.remove()
  }

  removeSrcFromNextTurboFrame() {
    let currentElement = this.element

    while ((currentElement = currentElement.parentNode)) {
      if (currentElement.tagName === 'TURBO-FRAME') {
        currentElement.removeAttribute('src')
        return
      }
    }
  }
}
