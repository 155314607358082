import { Autocomplete } from 'stimulus-autocomplete'

const optionSelector = "[role='option']:not([aria-disabled])"

// Adds support for hovering over items in the results list to select them.
//
// TODO: Remove this, when https://github.com/afcapel/stimulus-autocomplete/issues/125 has been resolved.
export default class extends Autocomplete {
  connect() {
    super.connect()
    this.resultsTarget.addEventListener('mouseover', this.onResultsMouseOver)
  }

  disconnect() {
    super.disconnect()
    if (this.hasResultsTarget) {
      this.resultsTarget.removeEventListener('mouseover', this.onResultsMouseOver)
    }
  }

  onResultsMouseOver = (event) => {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest(optionSelector)
    if (selected) this.select(selected)
  }
}
