import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'
import DragSort from '@yaireo/dragsort'
import { get } from '@rails/request.js'

export default class TagifyController extends Controller {
  static targets = ['input']

  static values = {
    autocomplete: { type: Boolean, default: false },
    delimiter: String,
    maxTags: Number,
    url: String,
  }

  connect() {
    this.load()
  }

  disconnect() {
    this.dragSort.destroy()
    this.tagify.destroy()
  }

  load() {
    let settings = {
      whitelist: [],
      dropdown: {
        enabled: 0,
        position: 'text', // place the dropdown near the typed text
        closeOnSelect: true, // close the dropdown after selecting a suggestion
        highlightFirst: true,
      },
      delimiters: ';',
    }

    if (this.maxTagsValue && this.maxTagsValue > 0) {
      settings.maxTags = this.maxTagsValue
    }

    if (this.delimiterValue) {
      settings.delimiters = this.delimiterValue
      settings.originalInputValueFormat = (valuesArr) =>
        valuesArr.map((item) => item.value).join(this.delimiterValue)
    }

    this.tagify = new Tagify(this.inputTarget, settings)

    if (this.autocompleteValue) {
      let autocompleteUrl = this.urlValue

      this.tagify.on('input', function (event) {
        event.detail.tagify.whitelist = null // reset the whitelist
        let url = new URL(autocompleteUrl, window.location.origin)
        url.searchParams.set('q', event.detail.value)

        // show loading animation and hide the suggestions dropdown
        event.detail.tagify.loading(true)
        get(url.toString(), { responseKind: 'json' })
          .then(({ ok, json }) => {
            if (ok) {
              json.then((data) => {
                event.detail.tagify.whitelist = data // update whitelist Array in-place
              })
              event.detail.tagify.loading(false).dropdown.show() // render the suggestions dropdown
            } else {
              event.detail.tagify.loading(false).dropdown.hide()
            }
          })
          .catch(() => {
            event.detail.tagify.loading(false).dropdown.hide()
          })
      })
    }

    this.dragSort = new DragSort(this.tagify.DOM.scope, {
      selector: '.' + this.tagify.settings.classNames.tag,
      callbacks: {
        dragEnd: function () {
          // must update Tagify's value according to the re-ordered nodes in the DOM
          this.tagify.updateValueByDOMTags()
        },
      },
    })
  }
}
