import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    assetHostDomain: String,
  }

  connect() {
    if ('serviceWorker' in navigator) {
      if (!navigator.serviceWorker.controller) {
        // Register the service worker, and wait for it to become active
        let self = this

        navigator.serviceWorker.register('/service-worker.js', { scope: '/' }).then(
          function (registration) {
            console.log(
              '[serviceWorkerController]',
              'registration successful with scope: ',
              registration.scope,
            )

            registration.addEventListener('updatefound', function () {
              console.log('[serviceWorkerController]', 'updatefound')

              caches.open('documents').then(function (cache) {
                let links = document.querySelectorAll('a[href^="/"]:not([rel="nofollow"])')
                let urls = Array.from(links).map((elem) => elem.getAttribute('href'))
                urls = urls.concat(document.location.pathname)
                cache.addAll(Array.from(new Set(urls)))
              })

              caches.open('assets-styles-and-scripts').then(function (cache) {
                let queryLinks = (attribute, defaultSelector, optionalSelector) => {
                  let stylesheetSelector = [defaultSelector]
                  if (self.hasAssetHostDomainValue) {
                    stylesheetSelector.push(optionalSelector)
                  }
                  let links = document.querySelectorAll(stylesheetSelector.join(','))
                  let urls = Array.from(links).map((elem) => elem.getAttribute(attribute))
                  return Array.from(new Set(urls))
                }

                cache.addAll(
                  queryLinks(
                    'href',
                    'link[rel="stylesheet"][href^="/"]',
                    `link[rel="stylesheet"][href^="${self.assetHostDomainValue}"]`,
                  ),
                )

                cache.addAll(
                  queryLinks(
                    'src',
                    'script[src^="/"]',
                    `script[src^="${self.assetHostDomainValue}"]`,
                  ),
                )
              })
            })

            // Disable Turbo Drive for this session.
            // This ensures that the ServiceWorker is active and that we do not re-run this controller by accident
            Turbo.session.drive = false
          },
          function (err) {
            console.log('[serviceWorkerController]', 'registration failed: ', err)
          },
        )
      }
    }
  }
}
