import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

// This controller is necessary to work-around an issue with Turbo Streams
// See https://github.com/hotwired/turbo/issues/491
export default class extends Controller {
  getTurboSteam(event) {
    event.preventDefault()
    get(event.target.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream',
    })
  }
}
