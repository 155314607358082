import '@hotwired/turbo-rails'
import appsignal from './appsignal'

document.addEventListener('turbo:before-fetch-request', (event) => {
  // Turbo Drive does not send a referrer like Turbolinks used to, so let's simulate it here
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
})

document.addEventListener('turbo:frame-missing', (event) => {
  const errorMessage = new Error(`Frame missing: ${event.detail.url}`)
  console.warn('turbo', errorMessage)
  appsignal.sendError(new Error(errorMessage))
})
