// Stimulus controllers at components/*_controller.js
import { Application } from '@hotwired/stimulus'
import appsignal from './appsignal'
import { installErrorHandler } from '@appsignal/stimulus'

const application = Application.start()

// Integrate with AppSignal
// https://docs.appsignal.com/front-end/integrations/stimulus.html
installErrorHandler(appsignal, application)

// Enable debug mode in development environment
application.debug = process.env.NODE_ENV === 'development'

window.Stimulus = application
