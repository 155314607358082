import Uppy from '@uppy/core'
import German from '@uppy/locales/lib/de_DE'
import AwsS3 from '@uppy/aws-s3'
import Dashboard from '@uppy/dashboard'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'fileInput',
    'fileDescription',
    'hiddenInput',
    'form',
    'title',
    'formFields',
    'submitBtn',
  ]

  static values = {
    maxFileSize: Number,
    locale: String,
  }

  connect() {
    super.connect()
    this.successfulUploads = []
    this.configs = {
      maxFileSize: this.maxFileSizeValue,
      allowedFileTypes: ['application/pdf', '.pdf'],
    }

    this.uppy = this.initUppy()
    this.initUppyAws()
    this.initUppyUploadProgress()
    this.initUppyUploadSuccess()
  }

  disconnect() {
    this.uppy.close()
  }

  initUppy() {
    let uppy = new Uppy({
      autoProceed: true,
      locale: this.locale,
      restrictions: {
        maxFileSize: this.configs.maxFileSize,
        allowedFileTypes: this.configs.allowedFileTypes,
      },
    }).use(Dashboard, {
      target: this.fileInputTarget,
      inline: true,
      note: this.fileDescriptionTarget.innerText,
      width: 680,
      showProgressDetails: true,
      height: 430,
      proudlyDisplayPoweredByUppy: false,
    })

    return uppy
  }

  get locale() {
    return this.localeValue === 'de' ? German : null
  }

  resetUppy() {
    this.uppy.reset()
    this.formFieldsTarget.classList.add('hidden')
    this.formTarget.reset()
    this.successfulUploads = []
  }

  initUppyAws() {
    this.uppy.use(AwsS3, {
      getUploadParameters(file) {
        const filename = encodeURIComponent(file.meta.name)
        const type = encodeURIComponent(file.meta.type)
        return fetch(`/publications/files/new/presign?filename=${filename}&type=${type}`).then(
          (response) => response.json(),
        )
      },
    })
  }

  initUppyUploadSuccess() {
    let self = this
    this.uppy.on('upload-success', function (file, response) {
      self.successfulUploads.push(file)
      self.formFieldsTarget.classList.remove('hidden')
    })
  }

  initUppyUploadProgress() {
    let self = this
    this.uppy.on('upload', (file, progress) => {
      self.submitBtnTarget.disabled = true
    })
    this.uppy.on('complete', (file, progress) => {
      self.submitBtnTarget.disabled = false
    })
  }

  uploadedFileData(file) {
    // construct uploaded file data in the format that Shrine expects
    return JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    })
  }

  submit() {
    let uploadedFile = this.successfulUploads.shift()

    if (uploadedFile) {
      this.titleTarget.value = uploadedFile.meta.name
      this.hiddenInputTarget.value = this.uploadedFileData(uploadedFile)

      this.formTarget.requestSubmit()
    } else {
      this.resetUppy()
    }
  }
}
