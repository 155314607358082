import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    content: String,
  }

  connect() {
    tippy(this.element, {
      theme: 'material',
      content: this.contentValue,
    })
  }

  disconnect() {
    this.element._tippy.destroy()
  }
}
